import React from 'react';
import { hideModal } from 'src/js/actions/xelacore';
import { connect } from 'react-redux';
import { Modal } from 'components/static';
import Button from 'modules/UiKit/components/Button/Button';
import { useTranslation } from 'react-i18next';

function ReportActionConfirmation({message, dispatch, confirm, title}) {
    const { t } = useTranslation();

    const confirmFunction = () => {
        confirm();
        dispatch(hideModal())
    }

    const body = (
        <div className="o-box--block report-deletion">
            <p className="u-color-black">
                <strong>
                    {message}
                </strong>
            </p>

            <div
                className={"u-flex-gap u-flex-align--center"}
            >
                <Button
                    type="secondary-danger-2"
                    size="small"
                    onClick={() => confirmFunction()}
                >
                    {t('buttons.delete')}
                </Button>
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => dispatch(hideModal())}
                >
                    {t('buttons.cancel')}
                </Button>
            </div>
        </div>
    );

    return (
        <Modal
            title={title}
            body={body}
        />
    )
}

const mapStateToProps = ({
    xelacore,
    dispatch
}) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(ReportActionConfirmation);
