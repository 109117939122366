import React from 'react';
import { connect } from 'react-redux';
import Button from 'modules/UiKit/components/Button/Button';
import toArray from 'lodash/toArray';
import { showModal } from 'src/js/actions/xelacore';
import ReportActionConfirmation from './ReportActionConfirmation';
import { useTranslation } from 'react-i18next';

function ReportingPeriods({
    salesData,
    setOpenUploadArea,
    exportCsvFunc,
    deleteReport,
    dispatch
}) {
    const { t } = useTranslation();

    const deleteReportEntries = (reportId) => {
        const modalContent = (
            <ReportActionConfirmation
                title={t('modules.royalty_reporting.reporting_periods.delete_confirmation')}
                confirm={() => deleteReport(reportId)}
                message={t('modules.royalty_reporting.reporting_periods.you_want_delete')}
            />
        );

        return dispatch(showModal(modalContent));
    }

    const sortedData = salesData.sort((a, b) => {
        const [quarterA, yearA] = a.yearQuarter.split('-');
        const [quarterB, yearB] = b.yearQuarter.split('-');

        if (yearA !== yearB) {
            return yearB - yearA;
        }

        return quarterB.slice(1) - quarterA.slice(1);
    });

    return (
        <div className='royalty-report-assistant --display-data'>
            <div className='report-period'>
                <h2>{t('modules.royalty_reporting.royalty_report_title')}</h2>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => setOpenUploadArea(true)}
                >
                    {t('modules.royalty_reporting.reporting_periods.create_period')}
                </Button>
            </div>

            <div className='report-period-body'>
                {toArray(sortedData).map((el) => {
                    return (
                        <div className='report-period-group'>
                            <div className='period-title'>
                                {t('modules.royalty_reporting.reporting_periods.reporting_period')} {el.quarter} {el.year}

                                <Button
                                    type="secondary-2"
                                    size="small"
                                    onClick={() => setOpenUploadArea(true, el)}
                                >
                                    {t('buttons.upload')}
                                </Button>

                                <Button
                                    type="secondary-danger"
                                    size="small"
                                    onClick={() => deleteReportEntries(el.id)}
                                >
                                    {t('buttons.delete')}
                                </Button>
                            </div>

                            <div className='period-items-wrapper'>
                                {el.salesPerLicensor.map((period) => {
                                    period.image = period.licensorId ? `https://assets.xelacore.io/org/${period.licensorId}.png` : null;

                                    return (
                                        <div className='period-item'>
                                            <div className='period-body'>
                                                <span className='image'>
                                                    <img src={period.image} onError={(event) => event.target.style.display = 'none'} />
                                                </span>
                                                <div className='description'>
                                                    <div className='title'>{t('modules.royalty_reporting.reporting_periods.no_of_records')}</div>
                                                    <div className='desc'>{period.count || 0}</div>
                                                </div>
                                            </div>

                                            <div className='period-footer'>
                                                <Button
                                                    type="secondary"
                                                    size="small"
                                                    to={`/royalty-report-assistant/${el.id}?quarter=${el.quarter}&year=${el.year}&licensor=${period.licensorId}`}
                                                >
                                                    {t('buttons.view')}
                                                </Button>

                                                <Button
                                                    type="secondary"
                                                    size="small"
                                                    onClick={() => exportCsvFunc(el.id, period.licensorId)}
                                                >
                                                    {t('buttons.export')}
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const mapStateToProps = ({
    xelacore,
    dispatch
}) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(ReportingPeriods);
