import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as MainOffice } from 'src/js/components/static/Icon/svg-icons/main-office.svg';
import { ReactComponent as RecipientDetails } from 'src/js/components/static/Icon/svg-icons/recipient-details.svg';
import { ReactComponent as PrimaryContact } from 'src/js/components/static/Icon/svg-icons/primary-contact.svg';
import { ReactComponent as BusinessInfo } from 'src/js/components/static/Icon/svg-icons/business-info.svg';
import { ReactComponent as SubmittedIcon } from 'src/js/components/static/Icon/svg-icons/submitted.svg';
import { ReactComponent as CloseIcon } from 'src/js/components/static/Icon/svg-icons/close-icon.svg';
import CustomSelect from 'modules/UiKit/components/FormElements/CustomSelect';
import Input from 'modules/UiKit/components/FormElements/Input';
import Textarea from 'modules/UiKit/components/FormElements/Textarea';
import AutocompleteDropdown from 'modules/Toolkit/TrademarkAuthorisationLetter/Components/AutocompleteDropdown';
import Button from 'modules/UiKit/components/Button/Button';
import { countriesSortedByContinent } from 'src/js/constants/countries';
import {
    fetchIps
} from 'src/js/apicalls/other/ips';
import map from 'lodash/map';
import find from 'lodash/find';
import { DynamicCategory } from 'components/ProductFormElements';
import { hideModal, showModal } from 'src/js/actions/xelacore';
import { Modal } from 'components/static';
import { sendRequest } from 'apicalls/other/tradeMark';
import { useTranslation } from 'react-i18next';

function TrademarkAuthorisationLetterForm({
    dispatch,
    licensors,
    xelacore,
    setTrademarkTableView,
    setFormView,
    getTradeMarks,
    isCounting,
    setIsCounting,
    trademarksExists
}) {
    const { t } = useTranslation();
    const [count, setCount] = useState(5);
    const [ips, setIps] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [category, setCategory] = useState({});
    const [note, setNote] = useState('');
    const [manufacturer, setManufacturer] = useState({});
    const {
        auth: { companyData = {} } = {}
    } = xelacore;

    const [formState, setFormState] = useState({
        licensor: '',
        targetMarket: [],
        ips: [],
        category: '',
        note: '',
        recipientDetails: {
            recipientsName: '',
            companyName: '',
            companyAddress: ''
        },
        businessInfo: {
            legalCompanyName: (companyData.information && companyData.information.legal_company_name) || '',
            companyRegistrationNumber: (companyData.information && companyData.information.company_registration_number) || '',
            vat: (companyData.information && companyData.information.uk_vat) || '',
            streetAddress: (companyData.information && companyData.information.address && companyData.information.address.street) || '',
            city: (companyData.information && companyData.information.address && companyData.information.address.town) || '',
            region: (companyData.information && companyData.information.address && companyData.information.address.region) || '',
            country: (companyData.information && companyData.information.address && companyData.information.address.country) || '',
            postcode: (companyData.information && companyData.information.address && companyData.information.address.postcode) || ''
        },
        primaryContact: {
            legalName:
                ((companyData.information &&
                    companyData.information.contacts &&
                    companyData.information.contacts.primary_ambassador &&
                    companyData.information.contacts.primary_ambassador.first_name) || '') +
                ' ' +
                ((companyData.information &&
                    companyData.information.contacts &&
                    companyData.information.contacts.primary_ambassador &&
                    companyData.information.contacts.primary_ambassador.last_name) || ''),
            email: (companyData.information &&
                companyData.information.contacts &&
                companyData.information.contacts.primary_ambassador &&
                companyData.information.contacts.primary_ambassador.email) || '',
            phoneNumber: (companyData.information &&
                companyData.information.contacts &&
                companyData.information.contacts.primary_ambassador &&
                companyData.information.contacts.primary_ambassador.biz_phone) || '',
            jobTitle: ''
        },
        manufacturerInfo: {
            manufacturer: '',
            manufacturerAddress: '',
            manufacturerCity: '',
            manufacturerRegion: '',
            manufacturerCountry: '',
            manufacturerPostcode: ''
        }
    });

    const steps = [
        {
            index: 0,
            value: 1,
            label: t('toolkit.trademark_form_steps.get_started')
        },
        {
            index: 1,
            value: 2,
            label: t('toolkit.trademark_form_steps.company_info')
        },
        {
            index: 2,
            value: 3,
            label: t('toolkit.trademark_form_steps.manufacturer_info')
        },
        {
            index: 3,
            value: 3,
            label: t('toolkit.trademark_form_steps.licensor_note')
        }
    ];

    useEffect(() => {
        fetchIpsFunc(formState.licensor);
    }, [formState.licensor]);

    useEffect(() => {
        let timer;
        if (isCounting && count > 0) {
            timer = setInterval(() => {
                setCount((prevCount) => prevCount - 1);
            }, 1000);
        } else if (count === 0) {
            setFormView(false);
            setTrademarkTableView(true);
            setIsCounting(false);
        }

        return () => clearInterval(timer);
    }, [isCounting, count]);

    const fetchIpsFunc = (licensor) => {
        const {
            auth: {
                token,
                companyData: { organisation_id }
            }
        } = xelacore;

        fetchIps(organisation_id, token, licensor).then((response) => {
            setIps(response.data && map(response.data.ips, el => {
                return {
                    label: el.string_path[0],
                    value: el.string_path[0],
                    name: el.string_path[0]
                };
            }));
        });
    };

    const startCountdown = () => {
        setCount(5); // Reset count to initial value
        setIsCounting(true); // Start countdown
    };

    const sendRequestFunc = () => {
        dispatch(hideModal());

        let params = {
            licensor_organisation_id: formState.licensor,
            manufacturer_id: manufacturer.id || null,
            products_info: {
                target_market: formState.targetMarket,
                ips: formState.ips,
                category: category
            },
            company_info: {
                legal_company_name: formState.businessInfo.legalCompanyName,
                company_registration_number: formState.businessInfo.companyRegistrationNumber,
                company_tax_number: formState.businessInfo.vat,
                address: {
                    street_address: formState.businessInfo.streetAddress,
                    city: formState.businessInfo.city,
                    region: formState.businessInfo.region,
                    country: formState.businessInfo.country,
                    postcode: formState.businessInfo.postcode
                },
                primary_contact: {
                    legal_name: formState.primaryContact.legalName,
                    job_title: formState.primaryContact.jobTitle,
                    email_address: formState.primaryContact.email,
                    phone_number: formState.primaryContact.phoneNumber
                }
            },
            recipient_info: {
                recipient_name: formState.recipientDetails.recipientsName,
                company_address: {
                    street_address: formState.recipientDetails.companyAddress,
                    company_name: formState.recipientDetails.companyName
                }
            },
            note: note
        };

        if(!manufacturer.id) {
            params.manufacturer = {
                name: manufacturer.name,
                address: {
                    street_address: formState.manufacturerInfo.manufacturerAddress,
                    city: formState.manufacturerInfo.manufacturerCity,
                    region: formState.manufacturerInfo.manufacturerRegion,
                    country: formState.manufacturerInfo.manufacturerCountry,
                    postcode: formState.manufacturerInfo.manufacturerPostcode
                }
            };
        }

        sendRequest(params).then(() => {
            getTradeMarks({});
            startCountdown();
        });
    };

    const findCurrentStep = () => {
        return find(steps, step => {
            return step.index === currentStep;
        });
    };

    const goNextStep = (step) => {
        if(currentStep === (steps.length - 1)) {
            confirmSubmit();
        } else {
            setCurrentStep(step + 1);
        }
    };

    const goPrevStep = (step) => {
        setCurrentStep(step - 1);
    };

    const handleInputChange = (e, group = null) => {
        const { name, value } = e.target;

        if (group) {
            setFormState((prev) => ({
                ...prev,
                [group]: {
                    ...prev[group],
                    [name]: value
                }
            }));
        } else {
            setFormState((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleSelectChange = (selectedValue, fieldName, group = null) => {
        if (group) {
            setFormState((prev) => ({
                ...prev,
                [group]: {
                    ...prev[group],
                    [fieldName]: selectedValue
                }
            }));
        } else {
            setFormState((prev) => ({
                ...prev,
                [fieldName]: selectedValue
            }));
        }
    };

    const handleManufacturerChange = (manufacturer) => {
        setManufacturer(manufacturer);
        if(manufacturer.id) {
            handleInputChange({target: {name: 'manufacturerAddress', value: manufacturer.address.street_address}}, 'manufacturerInfo');
            handleInputChange({target: {name: 'manufacturerCity', value: manufacturer.address.city}}, 'manufacturerInfo');
            handleInputChange({target: {name: 'manufacturerRegion', value: manufacturer.address.region}}, 'manufacturerInfo');
            handleInputChange({target: {name: 'manufacturerCountry', value: manufacturer.address.country}}, 'manufacturerInfo');
            handleInputChange({target: {name: 'manufacturerPostcode', value: manufacturer.address.postcode}}, 'manufacturerInfo');
        } else {
            handleInputChange({target: {name: 'manufacturerAddress', value: ''}}, 'manufacturerInfo');
            handleInputChange({target: {name: 'manufacturerCity', value: ''}}, 'manufacturerInfo');
            handleInputChange({target: {name: 'manufacturerRegion', value: ''}}, 'manufacturerInfo');
            handleInputChange({target: {name: 'manufacturerCountry', value: ''}}, 'manufacturerInfo');
            handleInputChange({target: {name: 'manufacturerPostcode', value: ''}}, 'manufacturerInfo');
        }
    };

    const confirmSubmit = () =>{
        const body = (
            <div className='u-full-width'>
                <div className='u-color-black u-margin-bottom'>
                    <div>
                        {t('toolkit.trademark_request_form.confirm_modal_title')}
                    </div>
                    <div className='marg-t-20 u-flex u-flex-align--left u-bold'>{t('toolkit.trademark_request_form.are_you_sure')}</div>
                </div>
                <div className='u-flex-gap u-flex-align--right'>
                    <Button
                        type='secondary'
                        size='small'
                        onClick={() => dispatch(hideModal())}>
                        {t('buttons.no')}
                    </Button>
                    <Button
                        size={'small'}
                        type={'primary'}
                        onClick={() => sendRequestFunc()}
                    >
                        {t('buttons.yes')}
                    </Button>
                </div>
            </div>
        );
        const modal = <Modal centered isSmall title={t('toolkit.trademark_request_form.confirm_modal_title')} body={body} />;
        return dispatch(showModal(modal));
    }

    return (
        <div>
            { !isCounting && (<div className='widget'>
                {trademarksExists && (<CloseIcon
                    className="c-modal__close-icon"
                    onClick={() => setFormView(false)} />)}

                <div className='widget-header'>
                    <div className='multi-step'>
                        <span className='counter'>{`${findCurrentStep(currentStep).value}/${steps.length - 1}`}</span>
                        <div className='multi-step-wrapper'>
                            <li className={`multi-step-wrapper__item ${currentStep === 0 ? 'active' : ''}`}></li>
                            <li className={`multi-step-wrapper__item ${currentStep === 1 ? 'active' : ''}`}></li>
                            <li className={`multi-step-wrapper__item ${currentStep === 2 || currentStep === 3 ? 'active' : ''}`}></li>
                        </div>
                    </div>

                    <b>{t('toolkit.trademark_letter_title')}</b>
                    <h2>{findCurrentStep().label}</h2>
                </div>

                {currentStep === 0 && (<div className='form-wrapper get-started'>
                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.licensor')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper select-wrapper'>
                                <CustomSelect
                                    name="licensor"
                                    values={licensors}
                                    value={formState.licensor}
                                    onChange={(selectedValue) => handleSelectChange(selectedValue, 'licensor')}
                                    allowEmpty={false}
                                    selectplaceholder={t('toolkit.trademark_request_form.select_placeholder')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.target_market_label')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper select-wrapper'>
                                <CustomSelect
                                    disabled={!formState.licensor}
                                    name={'country'}
                                    values={countriesSortedByContinent}
                                    value={formState.targetMarket}
                                    onChange={(selectedValue) => handleSelectChange(selectedValue, 'targetMarket')}
                                    allowEmpty={false}
                                    isGrouped={true}
                                    isMulti={true}
                                    enableSearch={true}
                                    entityName={t('toolkit.trademark_request_form.target_market')}
                                    type={'selectdropdown'}
                                    selectplaceholder={t('toolkit.trademark_request_form.select_placeholder')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.select_ips')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper select-wrapper'>
                                <CustomSelect
                                    disabled={!formState.licensor}
                                    name={'ip'}
                                    entityName={'Ip'}
                                    values={ips}
                                    value={formState.ips}
                                    onChange={(selectedValue) => handleSelectChange(selectedValue, 'ips')}
                                    allowEmpty={false}
                                    isMulti={true}
                                    selectplaceholder={t('toolkit.trademark_request_form.select_placeholder')}
                                />
                            </div>
                        </div>
                    </div>

                    <DynamicCategory
                        disabled={!formState.licensor}
                        value={formState.category}
                        dispatch={dispatch}
                        onChange={(e, value, path_string) => {
                            setCategory({e, value, path_string});
                        }}
                        xelacore={xelacore}
                        type={'licensee_category_path'}
                        isLicensor={false}
                    />
                </div>)}

                {currentStep === 1 && (<div className='form-wrapper company-info'>
                    <div className='form-group-title'>
                        <RecipientDetails></RecipientDetails>
                        {t('toolkit.trademark_request_form.recipient_details')}
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.recipients_name')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="recipientsName"
                                    value={formState.recipientDetails.recipientsName}
                                    onChange={(e) => handleInputChange(e, 'recipientDetails')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.company_name')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="companyName"
                                    value={formState.recipientDetails.companyName}
                                    onChange={(e) => handleInputChange(e, 'recipientDetails')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.company_address')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="companyAddress"
                                    value={formState.recipientDetails.companyAddress}
                                    onChange={(e) => handleInputChange(e, 'recipientDetails')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='form-group-title'>
                        <BusinessInfo></BusinessInfo>
                        {t('toolkit.trademark_request_form.business_info')}
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.legal_company_name')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="legalCompanyName"
                                    value={formState.businessInfo.legalCompanyName}
                                    onChange={(e) => handleInputChange(e, 'businessInfo')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.company_registration_name')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="companyRegistrationNumber"
                                    value={formState.businessInfo.companyRegistrationNumber}
                                    onChange={(e) => handleInputChange(e, 'businessInfo')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.vat')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="vat"
                                    value={formState.businessInfo.vat}
                                    onChange={(e) => handleInputChange(e, 'businessInfo')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='form-group-title'>
                        <MainOffice></MainOffice>
                        {t('toolkit.trademark_request_form.main_office_address')}
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.street_address')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="streetAddress"
                                    value={formState.businessInfo.streetAddress}
                                    onChange={(e) => handleInputChange(e, 'businessInfo')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.city')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="city"
                                    value={formState.businessInfo.city}
                                    onChange={(e) => handleInputChange(e, 'businessInfo')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.region')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="region"
                                    value={formState.businessInfo.region}
                                    onChange={(e) => handleInputChange(e, 'businessInfo')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.country')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="country"
                                    value={formState.businessInfo.country}
                                    onChange={(e) => handleInputChange(e, 'businessInfo')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.postcode')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="postcode"
                                    value={formState.businessInfo.postcode}
                                    onChange={(e) => handleInputChange(e, 'businessInfo')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='form-group-title'>
                        <PrimaryContact></PrimaryContact>
                        {t('toolkit.trademark_request_form.primary_contact')}
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.legal_name')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="legalName"
                                    value={formState.primaryContact.legalName}
                                    onChange={(e) => handleInputChange(e, 'primaryContact')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.email')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="email"
                                    value={formState.primaryContact.email}
                                    onChange={(e) => handleInputChange(e, 'primaryContact')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.phone_number')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="phoneNumber"
                                    value={formState.primaryContact.phoneNumber}
                                    onChange={(e) => handleInputChange(e, 'primaryContact')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.job_title')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Input
                                    name="jobTitle"
                                    value={formState.primaryContact.jobTitle}
                                    onChange={(e) => handleInputChange(e, 'primaryContact')}
                                />
                            </div>
                        </div>
                    </div>
                </div>)}

                {currentStep === 2 && (<div className='form-wrapper manufacturer'>
                        <div className={'form-item'}>
                            <label className={'main-label'}>
                                {t('toolkit.trademark_request_form.add_manufacturer')}
                            </label>

                            <div className='field-item-wrapper'>
                                <div className='extra-wrapper select-wrapper'>
                                    <AutocompleteDropdown
                                        value={manufacturer.name}
                                        onSelected={(e) => {
                                        handleManufacturerChange(e)
                                    }} />
                                </div>
                            </div>
                        </div>

                        { manufacturer.name && (
                            <div>
                                <div className='form-group-title'>
                                    <MainOffice></MainOffice>
                                    {t('toolkit.trademark_request_form.manufacturer_information')}
                                </div>

                                <div className={'form-item'}>
                                    <label className={'main-label'}>
                                        {t('toolkit.trademark_request_form.street_address')}
                                    </label>

                                    <div className='field-item-wrapper'>
                                        <div className='extra-wrapper input-wrapper'>
                                            <Input
                                                disabled={manufacturer.id}
                                                name="manufacturerAddress"
                                                value={formState.manufacturerInfo.manufacturerAddress}
                                                onChange={(e) => handleInputChange(e, 'manufacturerInfo')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'form-item'}>
                                    <label className={'main-label'}>
                                        {t('toolkit.trademark_request_form.city')}
                                    </label>

                                    <div className='field-item-wrapper'>
                                        <div className='extra-wrapper input-wrapper'>
                                            <Input
                                                disabled={manufacturer.id}
                                                name="manufacturerCity"
                                                value={formState.manufacturerInfo.manufacturerCity}
                                                onChange={(e) => handleInputChange(e, 'manufacturerInfo')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'form-item'}>
                                    <label className={'main-label'}>
                                        {t('toolkit.trademark_request_form.region')}
                                    </label>

                                    <div className='field-item-wrapper'>
                                        <div className='extra-wrapper input-wrapper'>
                                            <Input
                                                disabled={manufacturer.id}
                                                name="manufacturerRegion"
                                                value={formState.manufacturerInfo.manufacturerRegion}
                                                onChange={(e) => handleInputChange(e, 'manufacturerInfo')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'form-item'}>
                                    <label className={'main-label'}>
                                        {t('toolkit.trademark_request_form.country')}
                                    </label>

                                    <div className='field-item-wrapper'>
                                        <div className='extra-wrapper input-wrapper'>
                                            <Input
                                                disabled={manufacturer.id}
                                                name="manufacturerCountry"
                                                value={formState.manufacturerInfo.manufacturerCountry}
                                                onChange={(e) => handleInputChange(e, 'manufacturerInfo')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'form-item'}>
                                    <label className={'main-label'}>
                                        {t('toolkit.trademark_request_form.postcode')}
                                    </label>

                                    <div className='field-item-wrapper'>
                                        <div className='extra-wrapper input-wrapper'>
                                            <Input
                                                disabled={manufacturer.id}
                                                name="manufacturerPostcode"
                                                value={formState.manufacturerInfo.manufacturerPostcode}
                                                onChange={(e) => handleInputChange(e, 'manufacturerInfo')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {currentStep === 3 && (<div className='form-wrapper licensor-notes'>
                    <div className={'form-item'}>
                        <label className={'main-label'}>
                            {t('toolkit.trademark_request_form.note_to_licensor')}
                        </label>

                        <div className='field-item-wrapper'>
                            <div className='extra-wrapper input-wrapper'>
                                <Textarea
                                    name="note"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                    placeholder={t('toolkit.trademark_request_form.note_placeholder')}
                                />
                            </div>
                        </div>
                    </div>
                </div>)}

                <div className='form-wrapper buttons'>
                    <Button
                        type='primary'
                        size='medium'
                        disabled={currentStep === 0}
                        onClick={() => goPrevStep(currentStep)}
                    >
                        {t('buttons.back')}
                    </Button>
                    <Button
                        disabled={!formState.licensor}
                        type='primary'
                        size='medium'
                        onClick={() => goNextStep(currentStep)}
                    >
                        {currentStep === (steps.length - 1) ? t('buttons.finalise_request') : t('buttons.next')}
                    </Button>
                </div>
            </div>)}

            {isCounting && (
                <div className='widget'>
                    <div className='widget-header'>
                        <b>{t('toolkit.trademark_letter_title')}</b>
                        <h2>{t('toolkit.trademark_request_form.submission_sent')}</h2>
                    </div>

                    <div className={'form-wrapper u-text-center'}>
                        <SubmittedIcon></SubmittedIcon>
                        <div className={'u-margin-top u-bold'}>
                            {t('toolkit.trademark_request_form.thank_you_message')}
                        </div>
                        <h3 className={'u-margin-top'}>{t('toolkit.trademark_request_form.redirected_in')}{count}...</h3>
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = ({
     xelacore,
     dispatch
 }) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(TrademarkAuthorisationLetterForm);

