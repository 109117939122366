import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReportingPeriods from './Components/ReportingPeriods';
import UploadSalesData from './Components/UploadSalesData';
import {
    getReportPeriods,
    exportCsv,
    deleteReportPeriod
} from 'apicalls/other/royaltyReporting';
import size from 'lodash/size';
import toString from 'lodash/toString';
import {
    displayNotification
} from 'src/js/actions/xelacore';
import { allCountries } from 'constants/countries';
import lowerFirst from 'lodash/lowerFirst';
import map from 'lodash/map';

const quarters = [
    {
        value: 'q1',
        label: 'Q1',
        startDate: '01-01',
        endDate: '03-31',
        isHide: false
    },
    {
        value: 'q2',
        label: 'Q2',
        startDate: '04-01',
        endDate: '06-30',
        isHide: false
    },
    {
        value: 'q3',
        label: 'Q3',
        startDate: '07-01',
        endDate: '09-30',
        isHide: false
    },
    {
        value: 'q4',
        label: 'Q4',
        startDate: '10-01',
        endDate: '12-31',
        isHide: false
    }
]

const years = [
    {
        value: '2024',
        label: '2024',
        isHide: false
    },
    {
        value: '2023',
        label: '2023',
        isHide: false
    },
    {
        value: '2022',
        label: '2022',
        isHide: false
    },
    {
        value: '2021',
        label: '2021',
        isHide: false
    },
    {
        value: '2020',
        label: '2020',
        isHide: false
    }
]

const period = [
    {
        value: 'Wholesale',
        label: 'Wholesale'
    },
    {
        value: 'DTC',
        label: 'DTC'
    }
]

const wholesale = [
    {
        value: 'Landed',
        label: 'Landed'
    },
    {
        value: 'FOB',
        label: 'FOB'
    }
]


function RoyaltyReportAssistant({ dispatch }) {
    const [salesData, setSalesData] = useState([]);
    const [openUploadArea, setOpenUploadArea] = useState(false);
    const [busy, setBusy] = useState(false);
    const [year, setYear] = useState('');
    const [quarter, setQuarter] = useState('');
    const [periodExist, setPeriodExist] = useState(false);
    const [meta, setMeta] = useState({});
    const [reportId, setReportId] = useState('');

    useEffect(() => {
        getReportsPeriods({ page: 1, pageSize: 1000 });
    }, [])

    const getReportsPeriods = (params) => {
        setBusy(true);

        getReportPeriods(params).then(response => {
            let parsedData = groupByQuarterAndYear(response.data);
            setSalesData(parsedData);
            setMeta(response.data.meta)
            setBusy(false);
        })
    }

    const groupByQuarterAndYear = (data) => {
        return map(data, item => {
            const date = new Date(item.startDate);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            let quarter;

            if (month >= 1 && month <= 3) {
                quarter = 'Q1';
            } else if (month >= 4 && month <= 6) {
                quarter = 'Q2';
            } else if (month >= 7 && month <= 9) {
                quarter = 'Q3';
            } else {
                quarter = 'Q4';
            }


            return {
                year,
                month,
                quarter,
                yearQuarter: quarter + '-' + year,
                ...item
            }
        });
    }

    const openUploadAreaFunc = (attr, el) => {
        setOpenUploadArea(attr);

        if(!!el && !!el.year && !!el.quarter) {
            setYear(toString(el.year));
            setQuarter(lowerFirst(el.quarter));
            setPeriodExist(!!el.salesPerLicensor && el.salesPerLicensor.length > 0);
            setReportId(el.id);
        } else {
            setPeriodExist(false);
            setYear('');
            setQuarter('');
            setReportId('');
        }
    }

    const exportCsvFunc = (reportId, licensorId) => {
        exportCsv(reportId, licensorId).then(() => {}, () => {
            dispatch(
                displayNotification({
                    message: 'Oops... Something went wrong. Please try again.',
                    type: 'error',
                    timeOut: 5000
                })
            );
        })
    }

    const deleteReport = (reportId) => {
        deleteReportPeriod(reportId).then(() => {
            getReportsPeriods({ page: 1, pageSize: 1000 });

            dispatch(
                displayNotification({
                    message: 'Reporting Period deleted successfully.',
                    type: 'success',
                    timeOut: 5000
                })
            );
        })
    }

    return (
        <div className="royalty-report-assistant">
            {(size(salesData) > 0) && !openUploadArea && !busy && (
                <ReportingPeriods
                    wholesale={wholesale}
                    period={period}
                    years={years}
                    quarters={quarters}
                    openUploadArea={openUploadArea}
                    setOpenUploadArea={(attr, el) => openUploadAreaFunc(attr, el)}
                    fetchData={(params) => getReportPeriods(params)}
                    meta={meta}
                    exportCsvFunc={(reportId, licensorId) => exportCsvFunc(reportId, licensorId)}
                    deleteReport={(el) => deleteReport(el)}
                    salesData={salesData}/>
            )}

            {(size(salesData) === 0 || openUploadArea) && !busy && (
                <UploadSalesData
                    wholesale={wholesale}
                    period={period}
                    years={years}
                    quarters={quarters}
                    countries={allCountries}
                    openUploadArea={openUploadArea}
                    year={year}
                    quarter={quarter}
                    setYear={(year) => setYear(year)}
                    setQuarter={(val) => setQuarter(val)}
                    periodExist={periodExist}
                    fetchData={(params) => getReportsPeriods(params)}
                    setOpenUploadArea={(attr, el) => openUploadAreaFunc(attr, el)}
                    salesData={salesData}
                    reportId={reportId}
                />
            )}
        </div>
    );
}

const mapStateToProps = ({
    xelacore,
    dispatch
}) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(RoyaltyReportAssistant);

