import React, { useState, useEffect, useRef } from 'react';
import { getManufacturers } from 'apicalls/other/tradeMark';
import { useTranslation } from 'react-i18next';

const AutocompleteDropdown = ({ onSelected, value }) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState(value || '');
    const [results, setResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const dropdownRef = useRef(null);

    useEffect(() => {
        fetchInitialResults();

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleKeyPress = (e, query) => {
        if (e.key === 'Enter') {
            handleSelect({ name: query });
            setDropdownOpen(false);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    const fetchInitialResults = () => {
        getManufacturers().then(
            (response) => {
                setResults(response.data);
                setFilteredResults(response.data);
            },
            () => {}
        );
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setQuery(value);
        setDropdownOpen(true);

        if (value.length === 0) {
            setFilteredResults(results);
        } else {
            const filtered = results.filter((item) =>
                item.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredResults(filtered);
        }
    };

    const handleSelect = (item) => {
        setQuery(item.name);
        setDropdownOpen(false);
        if (onSelected) {
            onSelected(item);
        }
    };

    return (
        <div
            className="autocomplete-container"
            style={{ position: 'relative', width: '100%' }}
            ref={dropdownRef}
        >
            <input
                type="text"
                placeholder={t('toolkit.type_placeholder')}
                value={query}
                onChange={handleInputChange}
                onKeyPress={(e) => handleKeyPress(e, query)}
                onFocus={() => setDropdownOpen(true)}
                style={{ width: '100%' }}
            />
            {dropdownOpen && (
                <ul
                    className="autocomplete-dropdown"
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        right: 0,
                        border: '1px solid #ECECEE',
                        backgroundColor: '#fff',
                        listStyle: 'none',
                        padding: 0,
                        margin: '2px 0px 0 0',
                        zIndex: 1000,
                        maxHeight: '200px',
                        overflowY: 'auto',
                        borderRadius: '4px'
                    }}
                >
                    {filteredResults.length > 0 ? (
                        filteredResults.map((item) => (
                            <li
                                key={item.id}
                                onClick={() => handleSelect(item)}
                                style={{
                                    padding: '10px',
                                    cursor: 'pointer'
                                }}
                            >
                                {item.name}
                            </li>
                        ))
                    ) : (
                        <li style={{ padding: '10px' }}>
                            {t('toolkit.manufacturers_autocomplete_placeholder')}
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};

export default AutocompleteDropdown;
