import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as TradeMarkIcon } from 'src/js/components/static/Icon/svg-icons/trademark.svg';
import { ReactComponent as ReportIcon } from 'src/js/components/static/Icon/svg-icons/report.svg';
import { ReactComponent as FeedbackIcon } from 'src/js/components/static/Icon/svg-icons/feedback.svg';
import { useTranslation } from 'react-i18next';

function Toolkit() {
    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    return (
        <div className='toolkit'>
            <div className='header'>
                <div className="u-flex-align">
                    <h2 className="u-margin-left">
                        {t('toolkit.toolkit_title')}
                    </h2>
                </div>
            </div>

            <div>
                <div className='body'>
                    <div className='group-header'>
                        <p className='group-subtitle'>{t('toolkit.toolkit_subtitle')}</p>
                        <h2>{t('toolkit.toolkit_title')}</h2>
                        <p>{t('toolkit.toolkit_description')}</p>
                    </div>

                    <div className='group-wrapper'>
                        <a href='/toolkit/trademark' className='item-wrapper'>
                            <TradeMarkIcon></TradeMarkIcon>
                            <div className={'u-text-left'}>
                                <p className='title'>{t('toolkit.trademark_letter_title')}</p>
                                <p className='subtitle'>{t('toolkit.submit_trademark')}</p>
                            </div>
                        </a>

                        <a href='/toolkit/royalty-report-assistant' className='item-wrapper'>
                            <ReportIcon></ReportIcon>
                            <div className={'u-text-left'}>
                                <p className='title'>{t('toolkit.royalty_report_assistant')}</p>
                                <p className='subtitle'>{t('toolkit.upload_sales_data')}</p>
                            </div>
                        </a>

                        <div className='item-wrapper'>
                            <FeedbackIcon></FeedbackIcon>
                            <div className={'u-text-left'}>
                                <p className='title'>{t('toolkit.provide_feedback')}</p>
                                <p className='subtitle'>{t('toolkit.let_us_know')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({
                             xelacore,
                             dispatch
 }) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(Toolkit);

