import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';

/**
 *
 * @return {Promise}
 */
export function sendRequest(data) {
    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/requests'].join('/'),
            method: 'post',
            data: data
        })
        .then(responseHandler);
}

/**
 *
 * @return {Promise}
 */
export function getTradeMarkLetters(params) {
    return axios
        .request({
            url: [config.baseUrl, `trademark-letter/api/requests?page=${params.page || 1}&limit=${params.limit || 10000}`].join('/'),
            method: 'get',
            headers: { 'Cache-Control': 'no-cache' }
        })
        .then(responseHandler);
}

/**
 *
 * @return {Promise}
 */
export function getManufacturers() {
    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/manufacturers?limit=10'].join('/'),
            method: 'get',
            headers: { 'Cache-Control': 'no-cache' }
        })
        .then(responseHandler);
}

export function generateExportPdf(id) {
    return axios
        .request({
            url: [config.baseUrl, `trademark-letter/api/requests/${id}/export-pdf`].join('/'),
            method: 'get',
            responseType: 'blob'
        })
        .then(response=> {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);

            window.open(url, '_blank');
        });
}

