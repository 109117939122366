import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import MediaView from 'components/MediaView';
import { allCountries } from 'constants/countries';
import find from 'lodash/find';
import { fetchLinkedLicensors } from 'apicalls/mixed/myRecords';
import recordPlaceholder from '../../../../../../statics/imgs/record-placeholder.png';
import Button from 'modules/UiKit/components/Button/Button';
import moment from 'moment/moment';
import { ReactComponent as ApprovedIcon } from 'src/js/components/static/Icon/svg-icons/approved.svg';
import { ReactComponent as PendingIcon } from 'src/js/components/static/Icon/svg-icons/pending.svg';
import { ReactComponent as RejectedIcon } from 'src/js/components/static/Icon/svg-icons/rejected-icon.svg';
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import size from 'lodash/size';
import toArray from 'lodash/toArray';
import last from 'lodash/last'
import { useTranslation } from 'react-i18next';
// import DataViewPagination from 'src/js/components/DataViewPagination/DataViewPagination';
import { hideModal, showModal } from 'src/js/actions/xelacore';
import { Modal } from 'components/static';
import { generateExportPdf } from 'apicalls/other/tradeMark';


function TradeMarkTable({
    xelacore,
    tradeMarksArray,
    dispatch
}) {
    const { t } = useTranslation();
    const [, setTableWidth] = useState(0);
    const [licensorsArray, setLicensorsArray] = useState([]);
    const orgId = xelacore.auth.companyData.organisation_id;
    const mainTable = useRef(null);
    const table = useRef(null);
    const scrollableheader = useRef(null);
    const scrollbar = useRef(null);

    const scrollHandler = () => {
        const distanceToBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
        const tableElement = table.current;
        const rect = tableElement.getBoundingClientRect();
        const tableDistanceToBottom = Math.max(0, document.documentElement.scrollHeight - (rect.bottom + window.pageYOffset));

        if (window.scrollY > 140) {
            document.getElementById('siteHeader').style.boxShadow = 'none';

        } else {
            document.getElementById('siteHeader').style.boxShadow = '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        }

        if (distanceToBottom < tableDistanceToBottom + 40) {
            scrollbar.current.classList.add('bottom_fixed');
        } else {
            scrollbar.current.classList.remove('bottom_fixed');
        }

    };

    const handleScroll = (e, ref) => {
        if (ref === 'table') {
            scrollableheader.current.scrollTo(table.current.scrollLeft, 0);
            scrollbar.current.scrollTo(table.current.scrollLeft, 0);
        }

        if (ref === 'header') {
            table.current.scrollTo(scrollableheader.current.scrollLeft, 0);
            scrollbar.current.scrollTo(scrollableheader.current.scrollLeft, 0);
        }

        if (ref === 'scrollbar') {
            scrollableheader.current.scrollTo(scrollbar.current.scrollLeft, 0);
            table.current.scrollTo(scrollbar.current.scrollLeft, 0);
        }
    };

    const scrollHandlerBound = scrollHandler.bind();

    useEffect(() => {
        fetchLicensors();
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', scrollHandlerBound);

        setTimeout(() => {
            setTableWidth(mainTable.current.offsetWidth);
        }, 1000);

        return () => {
            document.removeEventListener('scroll', scrollHandlerBound);
            document.getElementById('siteHeader').style.boxShadow = '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        };
    }, []);

    const fetchLicensors = () => {
        return fetchLinkedLicensors(orgId).then(response => {
            setLicensorsArray(response.data.links);
        });
    };

    const formatTargetMarket = (v) => {
        if (!v || !Array.isArray(v)) return '';

        const formattedList = v.map(el => {
            const country = allCountries.find(f => f.value === el);
            return country ? `${country.label} (${el})` : null;
        }).filter(Boolean);

        const visibleItems = formattedList.slice(0, 3);
        const remainingCount = formattedList.length - 3;

        return (
            <span>
                {visibleItems.join(', ')}
                {remainingCount > 0 && (
                    <span>
                        , <a className={'view-more'} href="#" onClick={() => viewMore(formattedList, 'Target Markets')}>+{remainingCount}</a>
                    </span>
                )}
            </span>
        );
    };

    const formatArray = (arr, title) => {
        if (!arr || !Array.isArray(arr)) return '';

        const visibleItems = arr.slice(0, 2);
        const remainingCount = arr.length - 2;

        return (
            <span>
                {visibleItems.join(', ')}
                {remainingCount > 0 && (
                    <span>
                        , <a className={'view-more'} href="#" onClick={() => viewMore(arr, title)}>+{remainingCount}</a>
                    </span>
                )}
            </span>
        );
    }

    const findLicensor = (licesnor) => {
        return find(licensorsArray, el => {
            return el.licensor_organisation_id === licesnor;
        });
    };

    const exportPdf = (id) => {
        generateExportPdf(id).then(() => {});
    }

    const viewMore = (data, title) =>{
        const body = (
            <div className='u-full-width'>
                <div className='u-color-black u-margin-bottom'>
                    {data.map((el) => {
                        return (<div>
                            {el} {!last(el) ? ', ' : ''}
                        </div>)
                    })}
                </div>
                <div className='u-flex-gap u-flex-align--right'>
                    <Button
                        type='secondary'
                        size='small'
                        onClick={() => dispatch(hideModal())}>
                        Close
                    </Button>
                </div>
            </div>
        );
        const modal = <Modal centered isSmall title={title} body={body} />;
        return dispatch(showModal(modal));
    }

    return (
        <div className='royalty-report-wrapper trademark-table'>
            <div className="c-my-records">
                <div className={'ui-table table'}>
                    <div className="ui-table__wrapper table_ui">
                        <div className="ui-table__header-container">
                            <div
                                className="c-rec-table__table-holder table_header_wrapper"
                            >
                                <div ref={scrollableheader} onScroll={(e) => handleScroll(e, 'header')} className="table_wrapper_header">
                                    <table className="ui-table__top-header -dark">
                                        <thead>
                                        <tr>
                                            <th className='ui-table__head-cell action-cell'>{t('toolkit.trademark_table_headers.document')}</th>
                                            <th className='ui-table__head-cell licensor-column'>{t('toolkit.trademark_table_headers.licensor')}</th>
                                            <th className='ui-table__head-cell'>{t('toolkit.trademark_table_headers.status')}</th>
                                            <th className='ui-table__head-cell'>{t('toolkit.trademark_table_headers.requested_date')}</th>
                                            <th className='ui-table__head-cell'>{t('toolkit.trademark_table_headers.action_on')}</th>
                                            <th className='ui-table__head-cell'>{t('toolkit.trademark_table_headers.target_market')}</th>
                                            <th className='ui-table__head-cell'>{t('toolkit.trademark_table_headers.ips')}</th>
                                            <th className='ui-table__head-cell'>{t('toolkit.trademark_table_headers.product_category')}</th>
                                            <th className='ui-table__head-cell'>{t('toolkit.trademark_table_headers.product_subcategory')}</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='c-rec-table'>
                            <div
                                className='c-rec-table__table-holder c-rec-table__main-table'
                                ref={table}
                                onScroll={(e) => handleScroll(e, 'table')}
                                style={{ minHeight: 100 }}
                            >
                                <table>
                                    <tbody>
                                    {tradeMarksArray.map((el) => {
                                        el.org_image = `https://assets.xelacore.io/org/${el.licensor_organisation_id}.png`;

                                       return(
                                           <tr>
                                               <td
                                                   className='ui-table__body-cell action-cell'
                                               >
                                                   <span>
                                                       <Button
                                                           disabled={el.status !== 'approved'}
                                                           size={'small'}
                                                           type={'secondary'}
                                                           className='c-pointer'
                                                           onClick={() => exportPdf(el.id)}>
                                                           {t('buttons.view')}
                                                        </Button>
                                                   </span>
                                               </td>
                                               <td
                                                   className='ui-table__body-cell licensor-column'
                                               >
                                                   <div className={'u-flex-align'}>
                                                       <div className='c-rec-table__image-cell'>
                                                           <div className='c-rec-table__image-icon'>
                                                               <MediaView
                                                                   src={el.org_image}
                                                                   alt={'licensor_name'}
                                                                   fallbackSrc={recordPlaceholder}
                                                                   useImgTag
                                                               />
                                                           </div>
                                                       </div>
                                                       <span>
                                                           {findLicensor(el.licensor_organisation_id) && findLicensor(el.licensor_organisation_id).licensor_organisation_name || ''}
                                                           <span className={'ref-number'}>Ref ID: {el.id}</span>
                                                       </span>
                                                   </div>
                                               </td>
                                               <td
                                                   className='ui-table__body-cell'
                                               >
                                                   <span className={`u-flex-align ${el.status} status-cell`}>
                                                       {el.status === 'pending' && <PendingIcon></PendingIcon>}
                                                       {el.status === 'approved' && <ApprovedIcon></ApprovedIcon>}
                                                       {el.status === 'rejected' && <RejectedIcon></RejectedIcon>}
                                                       {capitalize(el.status)}
                                                   </span>
                                               </td>
                                               <td
                                                   className='ui-table__body-cell'
                                               >
                                                   <span>
                                                       {
                                                           moment(el.created_at).format('DD MMM YYYY')
                                                       }
                                                   </span>
                                               </td>
                                               <td className='ui-table__body-cell'>
                                                       <span>
                                                           {
                                                               el.status === 'rejected'
                                                                   ? moment(el.rejector_info.timestamp).format('DD MMM YYYY')
                                                                   : el.status === 'approved'
                                                                       ? moment(el.approver_info.timestamp).format('DD MMM YYYY')
                                                                       : 'N/A'
                                                           }
                                                       </span>
                                               </td>
                                               <td
                                                   className='ui-table__body-cell'
                                               >
                                                   <span>
                                                       {formatTargetMarket(el.products_info.target_market)}
                                                   </span>
                                               </td>
                                               <td
                                                   className='ui-table__body-cell'
                                               >
                                                   <span>
                                                       {formatArray(map(el.products_info.ips || [], 'value'), 'Ips')}
                                                   </span>
                                               </td>
                                               <td
                                                   className='ui-table__body-cell'
                                               >
                                                   <span>
                                                       {!!el.products_info && el.products_info.category && el.products_info.category.path_string && el.products_info.category.path_string[0] || ''}
                                                   </span>
                                               </td>
                                               <td
                                                   className='ui-table__body-cell'
                                               >
                                                   <span>
                                                       {!!el.products_info && el.products_info.category && el.products_info.category.path_string && el.products_info.category.path_string[1] || ''}
                                                   </span>
                                               </td>
                                           </tr>
                                       );
                                    })}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>

                    {/*<DataViewPagination*/}
                    {/*    extraclass={'marg-t-40'}*/}
                    {/*    totalItems={!!meta && !!meta.total_items && meta.total_items || 0}*/}
                    {/*    fetchData={(params) => getTradeMarks(params)}*/}
                    {/*    pageLimit={limit}*/}
                    {/*    pageNum={page}*/}
                    {/*/>*/}

                    <div
                        ref={scrollbar}
                        className='c-rec-table__bottom-scrollbar'
                        onScroll={(e) => handleScroll(e, 'scrollbar')}
                    >
                        <div
                            style={{
                                height: 20, width: (table.current && table.current.scrollWidth) || 0
                            }}
                        />
                    </div>
                </div>
            </div>

            {!size(tradeMarksArray) && !toArray(tradeMarksArray).length && (
                <div className="u-text-center u-margin-top-4X">
                    <h3 className="c-my-records__no-data-header">
                        {t('modules.royalty_reporting.no_data_available')}
                    </h3>
                    <div className='no-data-catalogue'>
                        <p className='c-my-records__no-data-text'>
                            {t('modules.royalty_reporting.you_have_no_data_here')}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = ({
                             xelacore,
                             dispatch
                         }) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(TradeMarkTable);
